// @ts-ignore
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import AuthService from "@/services/AuthService";
import i18n from "@/locales/index";

axios.defaults.baseURL = "https://" + process.env.VUE_APP_BACKEND_HOST;

console.info(
  "Backend URL:",
  axios.defaults.baseURL,
  process.env.VUE_APP_BACKEND_HOST
);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token: string = new AuthService().getAccessToken();
  if (config && config.headers) {
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
  }

  if (i18n.locale && config?.headers) {
    config.headers["Accept-Language"] =
      String(i18n.locale).toLowerCase() +
      "-" +
      String(i18n.locale).toUpperCase();
  }

  return config;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  function (error: any) {
    /*
        if (error.response && error.response.status === 503) {
          return router.push({ name: "MaintenanceMode" });
        }
        if (
          error.response &&
          401 === error.response.status &&
          "/login" !== window.location.pathname
        ) {
          const authService: AuthService = new AuthService();
          authService.setUnauthenticated();
          //window.location.href = "/login";
          return router.push({ name: "Login" });
        }
    
         */
    return Promise.reject(error);
  }
);
