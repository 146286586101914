import Vue from "vue";

Vue.filter(
  "filenameShorter",
  function (value: string, maxLength: number): string {
    const filenameParts = value.split(".");
    const extension: string = filenameParts[filenameParts.length - 1];
    const filename: string = filenameParts
      .slice(
        0,
        filenameParts.length >= 2
          ? filenameParts.length - 1
          : filenameParts.length
      )
      .join(".");

    const shortedString: string = filename.substring(0, maxLength);

    return [shortedString, "[...].", extension].join("");
  }
);
