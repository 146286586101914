import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// @ts-ignore
import BaseData from "@/router/BaseData";
import Customer from "@/router/Customer";
import Home from "../views/Home.vue";
import Machines from "@/router/Machines";
import SslCerts from "@/router/SslCerts";
import Users from "@/router/User";
import VueRouterMiddleware from "@/middlewares/VueRouterMiddleware";
import Auth from "@/router/Auth";
import auth from "@/middlewares/AuthMiddleware";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      title: "Home",
      middleware: [auth],
      breadcrumbs: { hidden: true },
    },
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/t",
    component: () =>
      import(/* webpackChunkName: "mis-tools" */ "../views/MisToolsView.vue"),
    children: [
      ...Auth,
      ...Machines,
      ...SslCerts,
      ...Users,
      ...BaseData,
      ...Customer,
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

new VueRouterMiddleware(router);

export default router;
