import auth from "@/middlewares/AuthMiddleware";
import { Software } from "@/config/Modules";

export default [
  {
    path: "software",
    component: () =>
      import(
        /* webpackChunkName: "software" */ "@/views/BaseData/Software/SoftwareView.vue"
      ),
    meta: {
      title: "Softwares",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "BaseDataSoftwareCreate",
        component: () =>
          import(
            /* webpackChunkName: "software-create" */ "@/views/BaseData/Software/SoftwareCreate.vue"
          ),
        meta: {
          title: "Software Create",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.software)", name: "BaseDataSoftwareList" },
            { title: "trans(general.create)", name: "BaseDataSoftwareCreate" },
          ],
        },
      },
      {
        path: ":id",
        name: "BaseDataSoftwareUpdate",
        component: () =>
          import(
            /* webpackChunkName: "software-update" */ "@/views/BaseData/Software/SoftwareUpdate.vue"
          ),
        meta: {
          title: "Software Update",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.software)", name: "BaseDataSoftwareList" },
            {
              vuex: {
                module: Software.store,
                getter: "getDataItem",
                column: "software",
              },
              name: "BaseDataSoftwareUpdate",
            },
          ],
        },
      },
      {
        path: "",
        name: "BaseDataSoftwareList",
        component: () =>
          import(
            /* webpackChunkName: "software-list" */ "@/views/BaseData/Software/SoftwareList.vue"
          ),
        meta: {
          title: "Software List",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.software)", name: "BaseDataSoftwareList" },
          ],
        },
      },
    ],
  },
];
