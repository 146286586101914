export default class VueRouterMiddleware {
  constructor(router: any) {
    router.beforeEach((to: any, from: any, next: any) => {
      if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
          ? to.meta.middleware
          : [to.meta.middleware];
        const context = { from, next, router, to };
        const nextMiddleware = this.nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
      }

      return next();
    });
  }

  nextFactory(context: any, middleware: any, index: any) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters: any) => {
      context.next(...parameters);
      const nextMiddleware = this.nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
    };
  }
}
