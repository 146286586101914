import { GetterTree } from "vuex";
import { CrudState } from "@/store/crud/State";

export const getError = (state: CrudState) => state.error;
export const getSuccess = (state: CrudState) => state.success;
export const getIsLoading = (state: CrudState) => state.isLoading;
export const getIsListLoading = (state: CrudState) => state.isListLoading;
export const getIsSubresourcesLoading = (state: CrudState) =>
  state.isSubResourceLoading;
export const getSubresources = (state: CrudState) => state.subResources;

export const getIsSaving = (state: CrudState) => state.isSaving;
export const getIsRemoving = (state: CrudState) => state.isRemoving;
export const getDataItem = (state: CrudState) => state.dataItem;
export const getDataList = (state: CrudState) => {
  return state.dataList ?? [];
};
export const getTotal = (state: CrudState) => state.total;
export const getDeletedSuccess = (state: CrudState) => state.deletedSuccess;
export const getValidationErrors = (state: CrudState) => state.validationErrors;

export const getItemFromListById =
  (state: CrudState) => (id: number, list: Array<any> | null) => {
    if (list) {
      return list.find((item: any) => {
        return item.id === id;
      });
    } else if (state.dataList) {
      return state.dataList.find((item: any) => {
        return item.id === id;
      });
    }
  };

export default <GetterTree<CrudState, any>>{
  getDataList,
  getTotal,
  getDataItem,
  getError,
  getIsLoading,
  getIsSubresourcesLoading,
  getSuccess,
  getDeletedSuccess,
  getIsListLoading,
  getIsSaving,
  getIsRemoving,
  getItemFromListById,
  getSubresources,
};
