import axios from "axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
// @ts-ignore
import queryString from "query-string";
import ApiService from "@/services/ApiService";

/**
 *Basic CRUD Service
 */

export default class CrudService {
  protected apiService: ApiService = new ApiService();

  /**
   *
   * @param options
   */
  public async findOne(options: ICrudOptions): Promise<any> {
    return this.apiService.get(
      options.resource + (options.id ? "/" + String(options.id) : "")
    );
  }

  /**
   *
   * @param options
   */
  public async findAll(options: ICrudOptions): Promise<any> {
    return this.apiService.get(options.resource, options.params);
  }
  /**
   *
   * @param options
   */
  public async findAllSubresource(options: ICrudOptions): Promise<any> {
    let url = `${options.mainResource}/${options.id}/${options.resource}`;
    // TODO code duplication
    let queryParams = "";
    if (options.params) {
      if (options.params.sort) {
        let sortQueryParam = "";
        for (const sortParam of options.params.sort) {
          sortQueryParam += `order[${sortParam.field}]=${sortParam.type}` + "&";
        }
        queryParams += sortQueryParam;
      }
      if (options.params.filter) {
        let filterQueryParam = "";
        for (const filterParam in options.params.filter) {
          filterQueryParam +=
            filterParam + "=" + options.params.filter[filterParam] + "&";
        }
        queryParams += filterQueryParam;
      }
      if (options.params.page) {
        const pageParam = "page=" + options.params.page + "&";
        queryParams += pageParam;
      }
      if (options.params.itemsPerPage) {
        const perPageParam =
          "itemsPerPage=" + options.params.itemsPerPage + "&";
        queryParams += perPageParam;
      }
    }

    if (options.extraQueryParams) {
      const eqp = Object.keys(options.extraQueryParams)
        .map((key: string) => {
          // @ts-ignore
          if (options.extraQueryParams && options.extraQueryParams[key]) {
            // @ts-ignore
            return key + "=" + options.extraQueryParams[key];
          }
        })
        .join("&");

      queryParams += "&" + eqp;
    }

    url += "?" + queryParams;

    return this.apiService.get(url);
  }

  /**
   *
   * @param options
   */
  public async create(options: ICrudOptions): Promise<any> {
    return this.apiService.post(options.resource, options.data);
  }

  /**
   *
   * @param options
   */
  public async update(options: ICrudOptions): Promise<any> {
    let url = options.resource + "/" + options.id;
    url += options.extraPath ? "/" + options.extraPath : "";
    return this.apiService.put(url, options.data);
  }

  /**
   *
   * @param options
   */
  public async delete(options: ICrudOptions): Promise<any> {
    return this.apiService.delete(options.resource + "/" + options.id);
  }

  public async uploadFile(options: any): Promise<any> {
    return this.apiService.upload(options);
  }
}
