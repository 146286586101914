import { State } from "./state";
import { MutationTree } from "vuex";
import {
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_SAVING,
  SET_IS_REMOVING,
} from "@/store/crud/functions/mutations";

export default <MutationTree<State>>{
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_SAVING,
  SET_IS_REMOVING,
};
