import { ActionContext } from "vuex";
import { CrudState } from "@/store/crud/State";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import CrudActions from "@/store/crud/classes/CrudActions";
import CrudResetActions from "@/store/crud/classes/CrudResetActions";
import mStore from "@/store";
import store from "@/store";

export const crudActions: CrudActions = new CrudActions();
export const crudResetActions: CrudResetActions = new CrudResetActions();

export async function findOne(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.findOne(store, options);
}

export async function findAll(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.findAll(store, options);
}

export async function findAllSubresource(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.findAllSubresource(store, options);
}

export async function create(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
) {
  return new Promise((resolve, reject) => {
    crudActions
      .create(store, options)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function update(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
) {
  return new Promise((resolve, reject) => {
    return crudActions
      .update(store, options)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function del(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.delete(store, options);
}

export async function upload(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.uploadFile(store, options);
}

export function resetList(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): void {
  crudResetActions.resetList(store, options);
}

export function resetSave(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): void {
  crudResetActions.resetSave(store, options);
}

/**
 * Store list response data for the new table store
 *
 * @param response
 */
export async function storeListResponse(response: any): Promise<any> {
  return crudActions.storeListResponse(response);
}
