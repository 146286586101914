import { CrudState } from "@/store/crud/State";
import ICustomer from "@/interfaces/ICustomer";
import IMachine from "@/interfaces/IMachine";

export class State {
  machines: Array<IMachine> = [];
  networks: Array<string> = [];

  machinesLoading = false;
  networksLoading = false;
}
