import { COUNTRY_CODES, FEATURED_COUNTRY_CODES } from "@/config/Consts";
import i18n from "@/locales/index";

export interface ICountry {
  code: string;
  name: string;
}

export default class CountryService {
  public getCountries(): Array<ICountry> {
    return COUNTRY_CODES.map((code: string) => {
      return {
        code: code,
        name: String(i18n.t("common_words.countries." + code)),
      };
    });
  }

  public getFeaturedCountries(): Array<ICountry> {
    return FEATURED_COUNTRY_CODES.map((code: string) => {
      return {
        code: code,
        name: String(i18n.t("common_words.countries." + code)),
      };
    });
  }

  public getCountryByCode(code: string): ICountry {
    return {
      code: code,
      name: String(i18n.t("common_words.countries." + code)),
    };
  }
}
