import { ToastProgrammatic as Toast } from "buefy";
import AppConfig from "@/config/AppConfig";

export default class MessageService {
  public success(msg: string) {
    this.msg(msg, "is-success");
  }

  public error(msg: string) {
    this.msg(msg, "is-danger");
  }

  public warning(msg: string) {
    this.msg(msg, "is-warning");
  }

  protected msg(
    msg: string,
    type: string,
    duration: number | null = null,
    position:
      | "is-top-right"
      | "is-top"
      | "is-top-left"
      | "is-bottom-right"
      | "is-bottom"
      | "is-bottom-left"
      | undefined = undefined
  ) {
    Toast.open({
      duration: duration ?? AppConfig.DEFAULT_TOAST_DURATION,
      message: msg,
      type: type,
      pauseOnHover: false,
      position: position ?? AppConfig.DEFAULT_TOAST_POSITION,
    });
  }
}
