// @ts-ignore
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import i18n from "@/locales";
import router from "@/router";

@Component
export default class DialogsService extends Vue {
  public deleteConfirmDialog(resource = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$buefy.dialog.confirm({
        title: String(i18n.t("confirm_dialogs.delete.title", { resource })),
        message: String(i18n.t("confirm_dialogs.delete.message", { resource })),
        confirmText: String(
          i18n.t("confirm_dialogs.delete.confirm_text", { resource })
        ),
        cancelText: String(
          i18n.t("confirm_dialogs.button.cancel", { resource })
        ),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => resolve(resource),
        onCancel: () => reject(resource),
      });
    });
  }

  public loggedOffWarning(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$buefy.dialog.alert({
        title: String(i18n.t("confirm_dialogs.logoff_warning.title")),
        message: String(i18n.t("confirm_dialogs.logoff_warning.message")),
        type: "is-warning",
        hasIcon: true,
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    });
  }

  public accessDeniedAlert(message = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$buefy.dialog.alert({
        title: String(i18n.t("confirm_dialogs.access_denied_alert.title")),
        message: String(message),
        type: "is-danger",
        hasIcon: true,
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    });
  }

  public onForgotPasswordAlert(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("onForgotPasswordAlert", email);
      this.$buefy.dialog.prompt({
        title: String(i18n.t("confirm_dialogs.on_forgot_password_alert.title")),
        message: String(
          i18n.t("confirm_dialogs.on_forgot_password_alert.message")
        ),
        inputAttrs: {
          type: "text",
          placeholder: "E-Mail",
          value: email,
        },
        type: "is-info",
        hasIcon: true,
        ariaRole: "alertdialog",
        ariaModal: true,
        trapFocus: true,
        onConfirm: () => {
          resolve(email);
        },
      });
    });
  }

  public onPasswordlessLoginAlert(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$buefy.dialog.alert({
        title: String(
          i18n.t("confirm_dialogs.on_passwordless_login_alert.title")
        ),
        message: String(
          i18n.t("confirm_dialogs.on_passwordless_login_alert.message")
        ),
        type: "is-info",
        hasIcon: true,
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    });
  }

  public onPasswordResetted(): any {
    this.$buefy.dialog.confirm({
      title: String(i18n.t("confirm_dialogs.on_password_resetted.title")),
      message: String(i18n.t("confirm_dialogs.on_password_resetted.message")),
      confirmText: String(
        i18n.t("confirm_dialogs.on_password_resetted.confirm_text")
      ),
      type: "is-success",
      hasIcon: true,
      onConfirm: () => {
        router.push({ name: "Login" });
      },
    });
  }

  public onEmailNotVerifiedDialog(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$buefy.dialog.confirm({
        title: String(i18n.t("confirm_dialogs.on_email_not_verified.title")),
        message: String(
          i18n.t("confirm_dialogs.on_email_not_verified.message")
        ),
        type: "is-warning",
        hasIcon: true,
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    });
  }
}
