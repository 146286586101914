import auth from "@/middlewares/AuthMiddleware";
import Customer from "@/router/BaseData/Customer";
import IpAddress from "@/router/BaseData/IpAddress";
import Machine from "@/router/BaseData/Machine";
import Software from "@/router/BaseData/Software";

export default [
  {
    path: "base-data",
    component: () =>
      import(
        /* webpackChunkName: "base-data-view" */ "../../views/BaseData/BaseDataView.vue"
      ),
    meta: {
      title: "Customers",
      middleware: [auth],
    },
    children: [...Customer, ...IpAddress, ...Machine, ...Software],
  },
];
