import axios, { AxiosRequestConfig } from "axios";
//import CryptService from "@/services/CryptService";
//import { NotificationProgrammatic as Notification } from "buefy";
import AppConfig from "@/config/AppConfig";
//import DialogsService from "@/services/DialogsService";

export interface ITransferProgress {
  loaded: number;
  total: number;
  percentage: number;
}

export default class ApiService {
  async get(path: string, params: any = {}) {
    return new Promise((resolve, reject) => {
      return axios
        .get(AppConfig.API_PREFIX + path, { params })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async post(path: string, data: any) {
    return new Promise((resolve, reject) => {
      return axios
        .post(AppConfig.API_PREFIX + path, { ...data }, {})
        .then((response) => {
          //console.debug("ApiService.post response", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async put(path: string, data: any) {
    return new Promise((resolve, reject) => {
      return axios
        .put(AppConfig.API_PREFIX + path, { ...data }, {})
        .then((response) => {
          console.debug("ApiService.put response", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async delete(path: string, params: any = {}, removeApiPrefix = false) {
    if (removeApiPrefix) {
      path = this.removeLeadingPrefix(path);
      console.debug("removed?", path);
    }

    return new Promise((resolve, reject) => {
      return axios
        .delete(AppConfig.API_PREFIX + path, { params })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async upload(options: any) {
    const config = {};
    const formData: any = new FormData();

    Object.keys(options.data).forEach((key: string) => {
      formData.append(key, options.data[key]);
    });

    // @ts-ignore
    config.headers = {
      "Content-Type": "multipart/form-data",
    };

    // urlSplit[0] = urlSplit[0].replace(/\/$/, "");

    options.resource = options.resource.replace(/^\/?(.*)/, "$1");

    return new Promise((resolve, reject) => {
      axios
        .post(AppConfig.API_PREFIX + "/" + options.resource, formData, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async download(
    path: string,
    params: any = {},
    getBlobUrlOnly = false,
    uploadProgressCallback: any = (transferProgress: ITransferProgress) => {}
  ) {
    const cfg: AxiosRequestConfig<any> = {
      params: params,
      responseType: "blob",
      onDownloadProgress: (progressEvent: any) => {
        const transferProgress: ITransferProgress = {
          loaded: progressEvent.loaded,
          total: progressEvent.total,
          percentage: Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          ),
        };

        uploadProgressCallback(transferProgress);
      },
    };

    path = path.replace(/^\/api(.*)/, "$1");

    const filename: string = params.filename ? params.filename : "download";

    return new Promise((resolve, reject) => {
      axios
        .get(AppConfig.API_PREFIX + path, cfg)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          if (!getBlobUrlOnly) {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            resolve(response);
            return;
          }
          resolve(url);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  protected removeLeadingPrefix(value: string, prefix = "/api"): string {
    const regex = new RegExp(`^${prefix}(.*)`);
    return value.replace(regex, "$1");
  }
}
