import { State } from "./state";
import { GetterTree } from "vuex";
import IMachine from "@/interfaces/IMachine";

export function getMachines(state: State): Array<IMachine> {
  return state.machines;
}

export function isMachinesLoading(state: State): boolean {
  return state.machinesLoading;
}

export function getNetworks(state: State): Array<string> {
  return state.networks;
}

export function isNetworksLoading(state: State): boolean {
  return state.networksLoading;
}

export default <GetterTree<State, any>>{
  getMachines,
  isMachinesLoading,
  getNetworks,
  isNetworksLoading,
};
