import Echo from "laravel-echo";
import AppConfig from "@/config/AppConfig";
import ApiService from "@/services/ApiService";
import AuthService from "@/services/AuthService";

const client = require("pusher-js");

const apiService = new ApiService();
const authService: AuthService = new AuthService();

export class MyEcho extends Echo {
  public constructor(options: any) {
    super(options);
  }

  public registerDefaultEvents() {
    // Register private only if authenticated

    if (authService.currentUser) {
      const user: any = authService.currentUser;

      this.private(`App.User.${user.id}`).listen(
        "UserDataUpdated",
        (e: any) => {
          /*
                    authService.setUser(e.user.user);
                    authService.setRoles(e.user.roles);
                     */
        }
      );
    }
  }
}

export default new MyEcho({
  broadcaster: "pusher",
  key: AppConfig.PUSHER.key,
  cluster: AppConfig.PUSHER.cluster,
  forceTLS: true,
  //authEndpoint: 'http://dev.api.muelleronline.org/api/broadcasting/auth'
  authEndpoint: "https://" + AppConfig.ENTRYPOINT + "/api/broadcasting/auth",
  auth: {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + authService.getAccessToken(),
    },
  },
});
