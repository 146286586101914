import { State } from "./state";
import { MutationTree } from "vuex";
import ICustomer from "@/interfaces/ICustomer";
import IMachine from "@/interfaces/IMachine";

export function SET_MACHINES(state: State, machines: Array<IMachine>): void {
  state.machines = machines;
}

export function SET_MACHINES_LOADING(state: State, loading = true): void {
  state.machinesLoading = loading;
}

export function SET_NETWORKS(state: State, networks: Array<string>): void {
  state.networks = networks;
}

export function SET_NETWORKS_LOADING(state: State, loading = true): void {
  state.networksLoading = loading;
}

export default <MutationTree<State>>{
  SET_MACHINES,
  SET_MACHINES_LOADING,
  SET_NETWORKS,
  SET_NETWORKS_LOADING,
};
