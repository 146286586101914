import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  findOne,
  findAll,
  resetSave,
  del,
  update,
  crudActions,
} from "@/store/crud/functions/actions";

export default <ActionTree<State, any>>{
  create,
  findOne,
  update,
  findAll,
  resetSave,
  del,
};
