import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  findOne,
  findAll,
  findAllSubresource,
  resetSave,
  del,
  update,
} from "@/store/crud/functions/actions";
import CrudService from "@/services/CrudService";
import AppConfig from "@/config/AppConfig";

export async function createNewCsr(
  store: ActionContext<State, any>,
  options: any
): Promise<any> {
  const crudService: CrudService = new CrudService();
  options = {
    resource: AppConfig.SSL_CERTIFICATE_RESOURCE + "/create-csr",
    ...options,
  };

  return new Promise((resolve, reject) => {
    crudService
      .create(options)
      .then((response: any) => {
        resolve(response);
        console.debug("Csr Create done", response.data);
      })
      .catch((error: any) => {
        console.error("Csr Create error", error.response);
        reject(error);
      });
  });
}
/*
export function findOne(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.findOne(store, options);
}
 */

export default <ActionTree<State, any>>{
  create,
  findOne,
  update,
  findAll,
  findAllSubresource,
  resetSave,
  del,
  createNewCsr,
};
