import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueI18n from "vue-i18n";
import i18n from "./locales";
import AuthService from "@/services/AuthService";
import ApiService from "@/services/ApiService";
import DialogsService from "@/services/DialogsService";
import CrudService from "@/services/CrudService";
import AppConfig from "@/config/AppConfig";
import VueAuthImage from "vue-auth-image";

// @ts-ignore
import VueEcho from "vue-echo-laravel";
import echo from "@/echo";
import CountryService from "@/services/CountryService";

Vue.config.productionTip = false;

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("./init/index");

Vue.prototype.$authService = new AuthService();
Vue.prototype.$apiService = new ApiService();
Vue.prototype.$dialogsService = new DialogsService();
Vue.prototype.$crudService = new CrudService();
Vue.prototype.$appConfig = AppConfig;
Vue.prototype.$countryService = new CountryService();

//Vue.prototype.$imageService = new ImageService();
// init as singleton:
//Vue.prototype.$notificationsService = NotificationsService.getInstance();

Vue.use(VueEcho, echo);

Vue.use(Buefy, {
  defaultIconPack: "fas",
});
Vue.use(VueI18n);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require("moment");
require("moment/locale/de");
require("moment/locale/en-gb");
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VueAuthImage);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
