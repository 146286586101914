import auth from "@/middlewares/AuthMiddleware";
import { IpAddress } from "@/config/Modules";

export default [
  {
    path: "ip-address",
    component: () =>
      import(
        /* webpackChunkName: "ip-address" */ "@/views/BaseData/IpAddress/IpAddressView.vue"
      ),
    meta: {
      title: "IpAddresses",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "BaseDataIpAddressCreate",
        component: () =>
          import(
            /* webpackChunkName: "ip-address-create" */ "@/views/BaseData/IpAddress/IpAddressCreate.vue"
          ),
        meta: {
          title: "IpAddress Create",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.ip_address)",
              name: "BaseDataIpAddressList",
            },
            { title: "trans(general.create)", name: "BaseDataIpAddressCreate" },
          ],
        },
      },
      {
        path: ":id",
        name: "BaseDataIpAddressUpdate",
        component: () =>
          import(
            /* webpackChunkName: "ip-address-update" */ "@/views/BaseData/IpAddress/IpAddressUpdate.vue"
          ),
        meta: {
          title: "IpAddress Update",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.ip_address)",
              name: "BaseDataIpAddressList",
            },
            {
              vuex: {
                module: IpAddress.store,
                getter: "getDataItem",
                column: "ip_address",
              },
              name: "BaseDataIpAddressUpdate",
            },
          ],
        },
      },
      {
        path: "",
        name: "BaseDataIpAddressList",
        component: () =>
          import(
            /* webpackChunkName: "ip-address-list" */ "@/views/BaseData/IpAddress/IpAddressList.vue"
          ),
        meta: {
          title: "IpAddress List",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.ip_address)",
              name: "BaseDataIpAddressList",
            },
          ],
        },
      },
    ],
  },
];
