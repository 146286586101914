import { State } from "./state";
import { GetterTree } from "vuex";

import {
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
} from "@/store/crud/functions/getters";

export function getToken(state: State): any {
  return state.token;
}

export function getLanguage(state: State): string {
  return state.language;
}

export function getAccessToken(state: State): string | null {
  return state.accessToken;
}

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getToken,
  getAccessToken,
  getLanguage,
};
