export default class Str {
  public static capitalize(char: string): string {
    if (!char) return "";
    return char.charAt(0).toUpperCase() + char.slice(1);
  }

  public static nl2Break(char: string): string {
    if (!char) return "";
    return char.replace(/\n/g, "<br/>");
  }
}
