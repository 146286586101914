import auth from "@/middlewares/AuthMiddleware";
import AppConfig from "@/config/AppConfig";

// users/create
// users

export default [
  {
    path: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../../views/User/UserView.vue"),
    meta: {
      title: "Users",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "UserCreate",
        component: () =>
          import(
            /* webpackChunkName: "user-create" */ "../../views/User/UserCreate.vue"
          ),
        meta: {
          title: "User Create",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.user)", name: "UserList" },
            { title: "trans(general.create)", name: "UserCreate" },
          ],
        },
      },
      {
        path: ":id",
        name: "UserUpdate",
        component: () =>
          import(
            /* webpackChunkName: "user-update" */ "../../views/User/UserUpdate.vue"
          ),
        meta: {
          title: "User Update",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.user)", name: "UserList" },
            {
              vuex: {
                module: AppConfig.USER_STORE,
                getter: "getDataItem",
                column: "name",
              },
              name: "UserUpdate",
            },
          ],
        },
      },
      {
        path: "",
        name: "UserList",
        component: () =>
          import(
            /* webpackChunkName: "user-list" */ "../../views/User/UserList.vue"
          ),
        meta: {
          title: "User List",
          middleware: [auth],
          breadcrumbs: [{ title: "trans(general.user)", name: "UserList" }],
        },
      },
    ],
  },
];
