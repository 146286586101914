export class CrudState {
  error: Record<string, any> = {};
  success: Record<string, any> = {};
  isLoading = false;
  isSubResourceLoading = {};
  isListLoading = false;
  isSaving = false;
  isRemoving = false;
  dataList: Array<any> = [];
  dataItem: Record<string, any> = {};
  subResources: any = {};
  total = 0;
  deletedSuccess: Record<string, any> = {};
  validationErrors: any = null;
}
