import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { CrudState } from "@/store/crud/State";
import { IpAddress, Machine } from "@/config/Modules";
import CrudService from "@/services/CrudService";

const crudService: CrudService = new CrudService();

export async function loadMachines(
  store: ActionContext<State, any>
): Promise<any> {
  if (
    store.getters["getMachines"].length < 1 &&
    !store.getters["isMachinesLoading"]
  ) {
    store.commit("SET_MACHINES_LOADING", true);

    return new Promise((resolve: any, reject: any) => {
      return crudService
        .findAll({
          resource: Machine.resource,
          params: {
            perPage: 10000,
          },
        })
        .then((response: any) => {
          if (response.data) {
            store.commit("SET_MACHINES", response.data.data);
          }
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => {
          store.commit("SET_MACHINES_LOADING", false);
        });
    });
  }
}

export async function loadNetworks(
  store: ActionContext<State, any>
): Promise<any> {
  if (
    store.getters["getNetworks"].length < 1 &&
    !store.getters["isNetworksLoading"]
  ) {
    store.commit("SET_NETWORKS_LOADING", true);

    return new Promise((resolve: any, reject: any) => {
      return crudService
        .findAll({
          resource: [IpAddress.resource, "networks"].join("/"),
        })
        .then((response: any) => {
          if (response.data) {
            store.commit("SET_NETWORKS", response.data);
          }
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => {
          store.commit("SET_NETWORKS_LOADING", false);
        });
    });
  }
}

export default <ActionTree<State, any>>{
  loadMachines,
  loadNetworks,
};
