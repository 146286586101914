import { GetterTree } from "vuex";
import { State } from "./state";
import IPaginationLinks from "@/store/table/interfaces/IPaginationLinks";
import IPaginationMeta from "@/store/table/interfaces/IPaginationMeta";

export function getIsLoading(state: State): any {
  return state.isLoading;
}

export function getDraggingRow(state: State): any {
  return state.draggingRow;
}

export function getDraggingRowIndex(state: State): number | null {
  return state.draggingRowIndex;
}

export function getPaginationLinks(state: State): IPaginationLinks {
  return state.paginationLinks;
}

export function getPaginationMeta(state: State): IPaginationMeta {
  return state.paginationMeta;
}

export function getCurrentPage(state: State): number {
  return state.currentPage;
}

export function getFilters(state: State): any {
  return state.filters;
}

export function getItemsPerPage(state: State): number {
  return state.itemsPerPage;
}

export function getSorts(state: State): Array<any> {
  return state.sorts;
}

export function getTotal(state: State): number {
  /*
    let total: number = 1;
public
    if (state.paginationMeta && state.paginationMeta.total) {
        public
        total = state.paginationMeta.total;
    }
    public
    return total;
     */
  return state.totalItems;
}

export function getTableParams(state: State): any {
  /*
  const tableParams: any = {
    ...state.filters,
    page: state.currentPage,
    perPage: state.itemsPerPage,
  };
  if (state.sorts.length > 0) {
    tableParams["order"] = state.sorts;
  }

  return tableParams;
  
   */
  const tableParams: any = {
    ...state.filters,
    page: state.currentPage,
    perPage: state.itemsPerPage,
  };
  if (state.sorts.length > 0) {
    const sorts: any = {};
    state.sorts.forEach((sort: any) => {
      sorts[sort.field] = sort.order;
    });
    tableParams["order"] = sorts;
  }
  return tableParams;
}

export default <GetterTree<State, any>>{
  getCurrentPage,
  getDraggingRow,
  getDraggingRowIndex,
  getFilters,
  getItemsPerPage,
  getPaginationLinks,
  getPaginationMeta,
  getSorts,
  getTableParams,
  getTotal,
  getIsLoading,
};
