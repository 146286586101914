import { State } from "./state";
import { GetterTree } from "vuex";

import {
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getIsListLoading,
  getSubresources,
  getIsSubresourcesLoading,
  getIsRemoving,
  getIsSaving,
} from "@/store/crud/functions/getters";

export function getFiles(state: State) {
  return state.subResources.files ?? [];
}

export function getFile(state: State) {
  return state.subResources.file ?? "";
}

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getSubresources,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getIsListLoading,
  getIsRemoving,
  getIsSaving,
  getFiles,
  getFile,
  getIsSubresourcesLoading,
};
