import i18n from "@/locales/index";

export const Auth = {
  store: "auth",
  description_field: "id",
};

export const Address = {
  resource: "/address",
  description_field: "label",
};

export const BaseDataCustomer = {
  resource: "/customer",
  store: "baseDataCustomer",
  description_field: "customer",
};

export const Customer = {
  resource: "/customer",
  store: "customer",
  description_field: "customer",
};

export const CustomerContract = {
  resource: "/customer/<customer_id>/contract",
  store: "customerContract",
  description_field: "title",
  getMediasUrl: (customerId: number, contractId: number) => {
    return String("/customer/<customer_id>/contract/<contract_id>/medias")
      .replace("<customer_id>", String(customerId))
      .replace("<contract_id>", String(contractId));
  },
};

export const IpAddress = {
  resource: "/ip-address",
  store: "ipAddress",
  description_field: "ip_address",
};

export const Machine = {
  resource: "/machine",
  store: "machine",
  description_field: "machine",
};

export const Reminder = {
  resource: "/reminder",
  store: "reminder",
  description_field: "first_execution",
  config: {
    first_remind_days_before: 31,
    second_remind_days_before: 7,
  },
};

export const Software = {
  resource: "/software",
  store: "software",
  description_field: "software",
};

export const User = {
  resource: "/user",
  store: "user",
  description_field: "name",
};
