import auth from "@/middlewares/AuthMiddleware";
import AppConfig from "@/config/AppConfig";
import { BaseDataCustomer } from "@/config/Modules";

export default [
  {
    path: "customers",
    component: () =>
      import(
        /* webpackChunkName: "customers" */ "@/views/BaseData/Customer/CustomerView.vue"
      ),
    meta: {
      title: "Customers",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "BaseDataCustomerCreate",
        component: () =>
          import(
            /* webpackChunkName: "customer-create" */ "@/views/BaseData/Customer/CustomerCreate.vue"
          ),
        meta: {
          title: "Customer Create",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.customer)", name: "BaseDataCustomerList" },
            { title: "trans(general.create)", name: "BaseDataCustomerCreate" },
          ],
        },
      },
      {
        path: ":id",
        name: "BaseDataCustomerUpdate",
        component: () =>
          import(
            /* webpackChunkName: "customer-update" */ "@/views/BaseData/Customer/CustomerUpdate.vue"
          ),
        meta: {
          title: "Customer Update",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.customer)", name: "BaseDataCustomerList" },
            {
              vuex: {
                module: BaseDataCustomer.store,
                getter: "getDataItem",
                column: "customer",
              },
              name: "BaseDataCustomerUpdate",
            },
          ],
        },
      },
      {
        path: "",
        name: "BaseDataCustomerList",
        component: () =>
          import(
            /* webpackChunkName: "customer-list" */ "@/views/BaseData/Customer/CustomerList.vue"
          ),
        meta: {
          title: "Customer List",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.customer)", name: "BaseDataCustomerList" },
          ],
        },
      },
    ],
  },
];
