import auth from "@/middlewares/AuthMiddleware";
import { Machine } from "@/config/Modules";

export default [
  {
    path: "machine",
    component: () =>
      import(
        /* webpackChunkName: "machine" */ "@/views/BaseData/Machine/MachineView.vue"
      ),
    meta: {
      title: "Machines",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "BaseDataMachineCreate",
        component: () =>
          import(
            /* webpackChunkName: "machine-create" */ "@/views/BaseData/Machine/MachineCreate.vue"
          ),
        meta: {
          title: "Machine Create",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.machine)",
              name: "BaseDataMachineList",
            },
            { title: "trans(general.create)", name: "BaseDataMachineCreate" },
          ],
        },
      },
      {
        path: ":id",
        name: "BaseDataMachineUpdate",
        component: () =>
          import(
            /* webpackChunkName: "machine-update" */ "@/views/BaseData/Machine/MachineUpdate.vue"
          ),
        meta: {
          title: "Machine Update",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.machine)",
              name: "BaseDataMachineList",
            },
            {
              vuex: {
                module: Machine.store,
                getter: "getDataItem",
                column: "machine",
              },
              name: "BaseDataMachineUpdate",
            },
          ],
        },
      },
      {
        path: "",
        name: "BaseDataMachineList",
        component: () =>
          import(
            /* webpackChunkName: "machine-list" */ "@/views/BaseData/Machine/MachineList.vue"
          ),
        meta: {
          title: "Machine List",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.machine)",
              name: "BaseDataMachineList",
            },
          ],
        },
      },
    ],
  },
];
