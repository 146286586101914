import Vue from "vue";
import Vuex from "vuex";
import Auth from "@/store/auth";
import createPersistedState from "vuex-persistedstate";
// @ts-ignore
import App from "@/store/app";
import Customer from "@/store/customer";
import Domain from "@/store/domain";
import IpAddress from "@/store/baseData/ipAddress";
import Machine from "@/store/baseData/machine";
import Table from "@/store/table";
import SslCertificate from "@/store/sslCertificate";
import User from "@/store/user";
import baseDataCustomer from "@/store/baseData/customer";
import CustomerContract from "@/store/customerContract";
import Reminder from "@/store/reminder";
import Selects from "@/store/selects";
import Software from "@/store/software";

Vue.use(Vuex);

const persistedStates = createPersistedState({
  paths: ["auth.token", "auth.accessToken", "auth.dataItem", "auth.language"],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: new App(),
    auth: new Auth(),
    baseDataCustomer: new baseDataCustomer(),
    customer: new Customer(),
    customerContract: new CustomerContract(),
    domain: new Domain(),
    ipAddress: new IpAddress(),
    machine: new Machine(),
    reminder: new Reminder(),
    selects: new Selects(),
    software: new Software(),
    sslCertificate: new SslCertificate(),
    table: new Table(),
    user: new User(),
  },
  plugins: [persistedStates],
});
