import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function clear(store: ActionContext<State, any>) {
  store.commit("IS_LOADING", false);
  store.commit("ERROR", "");
  store.commit("SUCCESS", null);
  store.commit("RETRIEVE_TOKEN", null);
}

export default <ActionTree<State, any>>{
  clear,
};
