import auth from "@/middlewares/AuthMiddleware";
import { Customer } from "@/config/Modules";
import Domains from "./domains";
import Contracts from "./contracts";

export default [
  {
    path: "customer/:id",
    component: () =>
      import(
        /* webpackChunkName: "customer-view" */ "@/views/Customer/CustomerView.vue"
      ),
    meta: {
      title: "Customers",
      middleware: [auth],
    },
    children: [
      {
        path: "dashboard",
        name: "CustomerDashboard",
        component: () =>
          import(
            /* webpackChunkName: "customer-dashboard" */ "@/views/Customer/CustomerDashboard.vue"
          ),
        meta: {
          title: "Customer Dashboard",
          middleware: [auth],
          breadcrumbs: [
            {
              vuex: {
                module: Customer.store,
                getter: "getDataItem",
                column: "customer",
              },
              name: "CustomerDashboard",
            },
            { title: "Dashboard", name: "CustomerDashboard" },
          ],
          show_in_aside: {
            aside: "customer",
            icon: "fas fa-dashboard",
            label: "modules.customer.aside.dashboard",
          },
        },
      },

      {
        path: "domains",
        name: "CustomerDomains",
        redirect: (to: any) => {
          return { name: "CustomerDomainList", params: { id: to.params.id } };
        },
        component: () =>
          import(
            /* webpackChunkName: "customer-domains" */ "@/views/Customer/CustomerDomains.vue"
          ),
        meta: {
          title: "Customer Domains",
          middleware: [auth],
          breadcrumbs: [
            {
              vuex: {
                module: Customer.store,
                getter: "getDataItem",
                column: "customer",
              },
              name: "CustomerDomains",
            },
            { title: "Domains", name: "CustomerDomains" },
          ],
          show_in_aside: {
            aside: "customer",
            icon: "fas fa-server",
            label: "modules.customer.aside.domains",
          },
        },
        children: [...Domains],
      },
      {
        path: "contracts",
        name: "CustomerContracts",
        redirect: (to: any) => {
          return { name: "CustomerContractList", params: { id: to.params.id } };
        },
        component: () =>
          import(
            /* webpackChunkName: "customer-contracts" */ "@/views/Customer/CustomerContracts.vue"
          ),
        meta: {
          title: "Customer Contracts",
          middleware: [auth],
          breadcrumbs: [
            {
              vuex: {
                module: Customer.store,
                getter: "getDataItem",
                column: "customer",
              },
              name: "CustomerContracts",
            },
            { title: "Domains", name: "CustomerContracts" },
          ],
          show_in_aside: {
            aside: "customer",
            icon: "fas fa-file-contract",
            label: "modules.customer.aside.contracts",
          },
        },
        children: Contracts,
      },
    ],
  },
];
