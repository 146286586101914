import { State } from "./state";
import { GetterTree } from "vuex";
import ICustomer from "@/interfaces/ICustomer";

export function getCurrentCustomer(state: State): ICustomer | null {
  return state.currentCustomer;
}

export default <GetterTree<State, any>>{
  getCurrentCustomer,
};
