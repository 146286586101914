import auth from "@/middlewares/AuthMiddleware";
import AppConfig from "@/config/AppConfig";

export default [
  {
    name: "SslCertsManagement",
    path: "ssl-certs",
    redirect: "/t/ssl-certs/domain",
    component: () =>
      import(
        /* webpackChunkName: "ssl-certs-management" */ "../../views/SslCerts/SslCertsManagement.vue"
      ),
    meta: {
      title: "SslCerts",
      middleware: [auth],
    },
    children: [
      {
        path: "domain",
        //        name: "DomainView",
        component: () =>
          import(
            /* webpackChunkName: "ssl-certs-domain" */ "../../views/SslCerts/domain/DomainView.vue"
          ),
        meta: {
          title: "Domain",
          middleware: [auth],
        },
        children: [
          {
            path: "create",
            name: "DomainCreate",
            component: () =>
              import(
                /* webpackChunkName: "ssl-certs-domain-create" */ "../../views/SslCerts/domain/DomainCreate.vue"
              ),
            meta: {
              title: "Domain Create",
              middleware: [auth],
              breadcrumbs: [
                { title: "trans(general.domain)", name: "DomainList" },

                { title: "trans(general.create)", name: "DomainCreate" },
              ],
            },
          },
          {
            path: ":id",
            name: "DomainUpdate",
            component: () =>
              import(
                /* webpackChunkName: "ssl-certs-domain-update" */ "../../views/SslCerts/domain/DomainUpdate.vue"
              ),
            meta: {
              title: "Domain Update",
              middleware: [auth],
              breadcrumbs: [
                { title: "trans(general.domain)", name: "DomainList" },
                {
                  vuex: {
                    module: AppConfig.DOMAIN_STORE,
                    getter: "getDataItem",
                    column: "url",
                  },
                  name: "DomainUpdate",
                },
              ],
            },
          },
          {
            path: "",
            name: "DomainList",
            component: () =>
              import(
                /* webpackChunkName: "ssl-certs-sslCertificate-list" */ "../../views/SslCerts/domain/DomainList.vue"
              ),
            meta: {
              title: "Domain List",
              middleware: [auth],
              breadcrumbs: [
                { title: "trans(general.domain)", name: "DomainList" },
              ],
            },
          },
        ],
      },
      {
        path: "certs",
        component: () =>
          import(
            /* webpackChunkName: "ssl-certs-view" */ "../../views/SslCerts/sslCerts/SslCertView.vue"
          ),
        meta: {
          title: "SslCerts Management",
          middleware: [auth],
          breadcrumbs: [
            { title: "trans(general.ssl_certs)", name: "SslCertsManagement" },
          ],
        },
        children: [
          {
            path: ":id/create-csr",
            name: "SslCertificateCsrCreate",
            component: () =>
              import(
                /* webpackChunkName: "ssl-certs-SslCertificate-createCsr" */ "../../views/SslCerts/sslCerts/SslCertCsrCreate.vue"
              ),
            meta: {
              title: "CSR Create",
              middleware: [auth],
              breadcrumbs: [
                { title: "trans(general.domain)", name: "DomainList" },
                {
                  vuex: {
                    module: AppConfig.SSL_CERTIFICATE_STORE,
                    getter: "getDataItem",
                    column: "id",
                  },
                  name: "SslCertificateUpdate",
                },
                {
                  title: "trans(breadcrumbs.ssl_certs.create_csr)",
                  name: "SslCertificateCsrCreate",
                },
              ],
            },
          },
          {
            path: ":id",
            name: "SslCertificateUpdate",
            component: () =>
              import(
                /* webpackChunkName: "ssl-certs-SslCertificate-update" */ "../../views/SslCerts/sslCerts/SslCertUpdate.vue"
              ),
            meta: {
              title: "SslCertificate Update",
              middleware: [auth],
              breadcrumbs: [
                { title: "trans(general.domain)", name: "DomainList" },
                {
                  vuex: {
                    module: AppConfig.SSL_CERTIFICATE_STORE,
                    getter: "getDataItem",
                    column: "id",
                  },
                  name: "SslCertificateUpdate",
                },
              ],
            },
          },
          {
            path: "",
            name: "SslCertificateList",
            component: () =>
              import(
                /* webpackChunkName: "ssl-certs-sslCertificate-list" */ "../../views/SslCerts/sslCerts/SslCertList.vue"
              ),
            meta: {
              title: "SslCertificate List",
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
];
