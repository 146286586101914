import IPaginationLinks from "@/store/table/interfaces/IPaginationLinks";
import IPaginationMeta from "@/store/table/interfaces/IPaginationMeta";

import AppConfig from "@/config/AppConfig";

export class State {
  private appConfig: AppConfig;

  constructor() {
    this.appConfig = new AppConfig();
  }

  isLoading = true;
  error: any = null;
  success: any = null;

  paginationLinks!: IPaginationLinks;
  paginationMeta!: IPaginationMeta;

  currentPage = 1;
  itemsPerPage: number = AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE;
  totalItems = 20;

  filters: any = {};
  sorts: Array<any> = [];

  // Table Drag and Drop properties
  draggingRow: any = null;
  draggingRowIndex: number | null = null;
}
