import { State } from "./state";
import { MutationTree } from "vuex";
import ICustomer from "@/interfaces/ICustomer";

export function SET_CURRENT_CUSTOMER(
  state: State,
  customer: ICustomer | null
): void {
  state.currentCustomer = customer;
}

export default <MutationTree<State>>{
  SET_CURRENT_CUSTOMER,
};
