import Vue from "vue";
import i18n from "@/locales";

Vue.filter("currency", function (value: number): string {
  const formatter = new Intl.NumberFormat(i18n.locale, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});
