import auth from "@/middlewares/AuthMiddleware";
import { Customer } from "@/config/Modules";

export default [
  {
    path: "",
    name: "CustomerDomainList",
    component: () =>
      import(
        /* webpackChunkName: "customer-domain-list" */ "@/views/Customer/Domains/DomainList.vue"
      ),
    meta: {
      title: "Customer Domainlist",
      middleware: [auth],
      breadcrumbs: [
        {
          vuex: {
            module: Customer.store,
            getter: "getDataItem",
            column: "customer",
          },
          name: "CustomerDashboard",
        },
        { title: "Domains", name: "CustomerDomainList" },
      ],
      show_in_aside: false,
    },
  },
];
