import IProviderCompany from "@/interfaces/IProviderCompany";

export const DOMAINS_WERE_CHECKED = "domains-were-checked";

export const MIME_TYPES: Array<{
  mime: string;
  extension: string;
}> = [
  {
    mime: "application/pdf",
    extension: ".pdf",
  },
  {
    mime: "application/msword",
    extension: ".doc",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    extension: ".docx",
  },
  {
    mime: "application/vnd.ms-excel",
    extension: ".xls",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: ".xlsx",
  },
  {
    mime: "application/vnd.ms-powerpoint",
    extension: ".ppt",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    extension: ".pptx",
  },
  {
    mime: "application/zip",
    extension: ".zip",
  },
  {
    mime: "application/x-gzip",
    extension: ".gz",
  },
  {
    mime: "application/json",
    extension: ".json",
  },
  {
    mime: "application/xml",
    extension: ".xml",
  },
  {
    mime: "application/xhtml+xml",
    extension: ".xhtml",
  },
  {
    mime: "application/rss+xml",
    extension: ".rss",
  },
  {
    mime: "application/octet-stream",
    extension: ".bin",
  },
  {
    mime: "application/javascript",
    extension: ".js",
  },
  {
    mime: "application/x-www-form-urlencoded",
    extension: "(usually no specific extension)",
  },
  {
    mime: "image/jpeg",
    extension: ".jpg, .jpeg",
  },
  {
    mime: "image/png",
    extension: ".png",
  },
  {
    mime: "image/gif",
    extension: ".gif",
  },
  {
    mime: "image/bmp",
    extension: ".bmp",
  },
  {
    mime: "image/webp",
    extension: ".webp",
  },
  {
    mime: "audio/mpeg",
    extension: ".mp3",
  },
  {
    mime: "audio/ogg",
    extension: ".ogg",
  },
  {
    mime: "video/mp4",
    extension: ".mp4",
  },
  {
    mime: "video/quicktime",
    extension: ".mov",
  },
  {
    mime: "text/plain",
    extension: ".txt",
  },
  {
    mime: "text/html",
    extension: ".html, .htm",
  },
  {
    mime: "text/css",
    extension: ".css",
  },
  {
    mime: "text/javascript",
    extension: ".js",
  },
  {
    mime: "text/xml",
    extension: ".xml",
  },
];

export const FEATURED_COUNTRY_CODES: Array<string> = [
  "DE",
  "FR",
  "GB",
  "US",
  "CH",
];

export const COUNTRY_CODES: Array<string> = [
  "AF",
  "AL",
  "DZ",
  "AD",
  "AO",
  "AG",
  "AR",
  "AM",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BT",
  "BO",
  "BA",
  "BW",
  "BR",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "DE",
  "KH",
  "CM",
  "CA",
  "CF",
  "TD",
  "CL",
  "CN",
  "CO",
  "KM",
  "CG",
  "CR",
  "HR",
  "CU",
  "CY",
  "CZ",
  "CD",
  "DK",
  "DJ",
  "DM",
  "DO",
  "TL",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FJ",
  "FI",
  "FR",
  "GA",
  "GM",
  "GE",
  "GH",
  "GR",
  "GD",
  "GT",
  "GN",
  "GW",
  "GY",
  "HT",
  "HN",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IL",
  "IT",
  "CI",
  "JM",
  "JP",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MR",
  "MU",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NZ",
  "NI",
  "NE",
  "NG",
  "KP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PL",
  "PT",
  "QA",
  "RO",
  "RU",
  "RW",
  "KN",
  "LC",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "KR",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TJ",
  "TZ",
  "TH",
  "TG",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "VN",
  "YE",
  "ZM",
  "ZW",
];

export const ProviderCompanies: Array<IProviderCompany> = [
  { name: "mis media it Service & Consulting GmbH", id: 1 },
  { name: "eupro group", id: 2 },
];
