import auth from "@/middlewares/AuthMiddleware";
import { Customer, CustomerContract } from "@/config/Modules";

export default [
  {
    path: "create",
    name: "CustomerContractCreate",
    component: () =>
      import(
        /* webpackChunkName: "customer-contract-create" */ "@/views/Customer/Contract/CustomerContractCreate.vue"
      ),
    meta: {
      title: "Customer Create",
      middleware: [auth],
      breadcrumbs: [
        {
          vuex: {
            module: Customer.store,
            getter: "getDataItem",
            column: "customer",
          },
          name: "CustomerContractCreate",
        },
        { title: "trans(general.contracts)", name: "CustomerContractCreate" },
      ],
    },
  },
  {
    path: ":contract_id",
    name: "CustomerContractUpdate",
    component: () =>
      import(
        /* webpackChunkName: "customer-contract-update" */ "@/views/Customer/Contract/CustomerContractUpdate.vue"
      ),
    meta: {
      title: "Customer Contract Update",
      middleware: [auth],
      breadcrumbs: [
        {
          vuex: {
            module: Customer.store,
            getter: "getDataItem",
            column: "customer",
          },
          name: "CustomerContractUpdate",
        },
        { title: "trans(general.contracts)", name: "CustomerContractList" },
        {
          vuex: {
            module: CustomerContract.store,
            getter: "getDataItem",
            column: "title",
          },
          name: "CustomerContractUpdate",
        },
      ],
    },
  },
  {
    path: "",
    name: "CustomerContractList",
    component: () =>
      import(
        /* webpackChunkName: "customer-contract-list" */ "@/views/Customer/Contract/CustomerContractList.vue"
      ),
    meta: {
      title: "Customer List",
      middleware: [auth],
      breadcrumbs: [
        {
          vuex: {
            module: Customer.store,
            getter: "getDataItem",
            column: "customer",
          },
          name: "CustomerContractList",
        },
        { title: "trans(general.contracts)", name: "CustomerContractList" },
      ],
    },
  },
];
