export default [
  {
    path: "login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login-view" */ "../../views/Auth/Login.vue"),
    meta: {
      title: "Login",
      //  middleware: [NotAuthenticatedMiddleware],
    },
  },
  {
    path: "login-with/:provider/callback",
    name: "LoginWithProviderCallback",
    component: () =>
      import(
        /* webpackChunkName: "login-with-provider-callback" */ "../../views/Auth/LoginWithCallback.vue"
      ),
    meta: {
      title: "Login",
      //  middleware: [NotAuthenticatedMiddleware],
    },
  },
];
