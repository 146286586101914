import auth from "@/middlewares/AuthMiddleware";

export default [
  {
    path: "machines",
    component: () =>
      import(
        /* webpackChunkName: "machines" */ "@/views/Machines/MachinesView.vue"
      ),
    meta: {
      title: "Machines",
      middleware: [auth],
    },
    children: [
      {
        path: "overview",
        name: "MachinesOverview",
        component: () =>
          import(
            /* webpackChunkName: "machines-overview" */ "@/views/Machines/MachinesOverview.vue"
          ),
        meta: {
          title: "Machines Overview",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.machines)",
              name: "MachinesOverview",
            },
          ],
        },
      },
      {
        path: "create-assistant",
        name: "MachinesCreateAssistant",
        component: () =>
          import(
            /* webpackChunkName: "machines-create-assistant" */ "@/views/Machines/MachineCreateAssistant.vue"
          ),
        meta: {
          title: "Machine Creator",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.machines)",
              name: "MachinesOverview",
            },
            {
              title: "trans(breadcrumbs.machines_create_assistant)",
              name: "MachinesCreateAssistant",
            },
          ],
        },
      },
    ],
  },
];
