import Vue from "vue";
import moment from "moment";

Vue.filter("hoursMinsDuration", function (minutes: number): any {
  if (!minutes) return "";
  //return moment(new Date(value)).format(DEFAULT_DATE_TIME_FORMAT);
  const dur = moment.duration(minutes, "minutes");
  const hours = Math.floor(dur.asHours());
  const mins = Math.floor(dur.asMinutes()) - hours * 60;
  // const sec = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;

  // const result = hours + ":" + mins + ":" + (sec > 9 ? sec : "0" + sec);
  return hours + ":" + mins;
});
