import { ActionContext } from "vuex";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

/**
 *Basic CRUD actions
 */
export default class CrudResetActions {
  public resourceName: string;

  constructor(resourceName = "DATA") {
    this.resourceName = resourceName;
  }

  /**
   *
   * @param store
   * @param options
   */
  public resetList(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): void {
    store.commit("SET_" + this.resourceName + "_LIST", []);
    store.commit("SET_" + this.resourceName + "_TOTAL", 0);
    store.commit("SET_" + this.resourceName + "_ERROR", null);
    store.commit("SET_" + this.resourceName + "_LOADING", false);
  }

  /**
   *
   * @param store
   * @param options
   */
  public resetSave(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): void {
    store.commit("SET_SUCCESS", false);
    store.commit("SET_DATA_ITEM", null);
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }
}
