import { MutationTree } from "vuex";
import { State } from "./state";
import IPaginationLinks from "@/store/table/interfaces/IPaginationLinks";
import IPaginationMeta from "@/store/table/interfaces/IPaginationMeta";

export function SUCCESS(state: State, success: any) {
  state.success = success;
}

export function SET_DATA_LOADING(state: State, loading: boolean) {
  state.isLoading = loading;
}

export function SET_DRAGGING_ROW(state: State, draggingRow: any) {
  state.draggingRow = draggingRow;
}

export function SET_DRAGGING_ROW_INDEX(
  state: State,
  draggingRowIndex: number | null
) {
  state.draggingRowIndex = draggingRowIndex;
}

export function SET_CURRENT_PAGE(state: State, currentPage: number) {
  state.currentPage = currentPage;
}

export function SET_FILTERS(state: State, filters: any) {
  state.filters = filters;
}

export function SET_ITEMS_PER_PAGE(state: State, itemsPerPage: number) {
  state.itemsPerPage = itemsPerPage;
}

export function SET_SORTS(state: State, sorts: Array<any>) {
  state.sorts = sorts;
}

export function SET_TOTAL_ITEMS(state: State, totalItems: number) {
  state.totalItems = totalItems;
}

export function SET_PAGINATION_LINKS(
  state: State,
  paginationLinks: IPaginationLinks
) {
  state.paginationLinks = paginationLinks;
}

export function SET_PAGINATION_META(
  state: State,
  paginationMeta: IPaginationMeta
) {
  state.paginationMeta = paginationMeta;
}

export default <MutationTree<State>>{
  SUCCESS,
  SET_CURRENT_PAGE,
  SET_DRAGGING_ROW,
  SET_DRAGGING_ROW_INDEX,
  SET_FILTERS,
  SET_DATA_LOADING,
  SET_ITEMS_PER_PAGE,
  SET_PAGINATION_LINKS,
  SET_PAGINATION_META,
  SET_SORTS,
  SET_TOTAL_ITEMS,
};
