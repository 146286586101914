import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  findOne,
  findAll,
  resetSave,
  del,
  update,
  crudActions,
} from "@/store/crud/functions/actions";
import { CrudState } from "@/store/crud/State";
import ApiService from "@/services/ApiService";
import { Customer } from "@/config/Modules";
import ICustomer from "@/interfaces/ICustomer";

export async function loadCustomerDashboardData(
  store: ActionContext<CrudState, any>,
  customer: ICustomer
): Promise<any> {
  store.commit("SET_IS_LOADING", true);

  return new Promise((resolve: any, reject: any) => {
    return new ApiService()
      .get([Customer.resource, String(customer.id), "dashboard"].join("/"))
      .then((response: any) => {
        if (response.data) {
          store.commit("SET_DASHBOARD", response.data);
        }
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      })
      .finally(() => {
        store.commit("SET_IS_LOADING", false);
      });
  });
}

export default <ActionTree<State, any>>{
  create,
  findOne,
  update,
  findAll,
  resetSave,
  del,
  loadCustomerDashboardData,
};
