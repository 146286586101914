import { CrudState } from "@/store/crud/State";

export function SET_DATA_LIST(state: CrudState, data: any) {
  state.dataList = data;
}
export function SET_TOTAL(state: CrudState, total: any) {
  state.total = total;
}

export function SET_DATA_ITEM(state: CrudState, item: any) {
  state.dataItem = item;
}

export function SET_ERROR(state: CrudState, error: any) {
  state.error = error;
}

export function SET_IS_LOADING(state: CrudState, isLoading: any) {
  state.isLoading = isLoading;
}

export function SET_SUCCESS(state: CrudState, success: any) {
  state.success = success;
}

export function SET_DELETED_SUCCESS(state: CrudState, deletedSuccess: any) {
  state.deletedSuccess = deletedSuccess;
}

export function SET_VALIDATION_ERRORS(state: CrudState, validationErrors: any) {
  state.validationErrors = validationErrors;
}

export function SET_IS_LIST_LOADING(state: CrudState, isListLoading: any) {
  state.isListLoading = isListLoading;
}

export function SET_IS_SUBRESOURCE_LOADING(
  state: CrudState,
  loadingResourceState: any
) {
  state.isSubResourceLoading = {
    ...state.isSubResourceLoading,
    ...loadingResourceState,
  };
}

export function SET_SUBRESOURCE(state: CrudState, resourceAndData: any) {
  state.subResources = {
    ...state.subResources,
    ...resourceAndData,
  };
}

export function SET_IS_SAVING(state: CrudState, isSaving: any) {
  state.isSaving = isSaving;
}

export function SET_IS_REMOVING(state: CrudState, isRemoving: any) {
  state.isRemoving = isRemoving;
}
